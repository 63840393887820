@use '@angular/material' as mat;
@use 'social-help-theme-vars' as vars;

@include mat.core();

@import url('social-help-theme-vars.scss');

$social-help-primary: mat.define-palette(mat.$indigo-palette, 500);
$social-help-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$social-help-warn: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$social-help-theme: mat.define-light-theme(
    (
        color: (
            primary: $social-help-primary,
            accent: $social-help-accent,
            warn: $social-help-warn,
        ),
        typography: mat.define-typography-config(),
        density: 0,
    )
);

@include mat.all-component-themes($social-help-theme);

:root {
    --color-page: #ffffff;
    --color-block-light: #ffffff;
    --color-block-grey: #98a2b2;
    --color-block-grey-light: #f0f1f2;
    --color-block-grey-very-light: #fafafa;
    --color-container: #f3f4fa;
    --color-container-blue-medium: #e8eaf6;
    --color-container-blue-light: #ebedfd;
    --color-warning: #ff4081;
    --color-warning-medium: #d1a7be;
    --conainer-border-radius: 20px;
    --tab-border-radius: 10px;
    --conainer-margin: 20px;
    --color-label-white: #ffffff;
    --color-label-black: #17171a;
    --color-label-grey: #98a2b2;
    --color-label-blue: #3f51b5;
    --color-label-green: #148012;
    --color-label-green-light: #dceadc;
    --color-label-green-verylight: #ecf3ec;
    --color-label-red: #d6338b;
    --color-label-coral: #ff8260;
    --shadow: 0 2px 5px rgb(23 23 26 / 0.15);
}

.page {
    padding: 12px;
    margin: 0;
    height: 97%;
}

.container {
    border-radius: var(--conainer-border-radius);
    height: 100%;
    &--main {
        background: var(--color-container) url('assets/images/home-illustration.png') bottom center no-repeat;
        background-size: 60%;
        @media (max-width: vars.$mobile-size) {
            background-size: contain;
        }
    }

    &--auth {
        background-color: var(--color-container);

        background-image: url('assets/images/home-top-left.png'), url('assets/images/home-bottom-right.png');
        background-position: left top, right bottom;
        background-repeat: no-repeat, no-repeat;

        background-size: 20%;
        @media (max-width: vars.$mobile-size) {
            background-position: -12vw top, right 80vh;
            background-size: 50%, 50%;
        }
    }

    &--client,
    &--admin {
        min-height: 95vh;
        border-radius: 20px;
        background: var(--color-container);
        margin-top: 10px;
        padding: 20px;

        @media (max-width: vars.$mobile-size) {
            padding: 5px;
            border-radius: 10px;
        }
    }
    &--admin-grey {
        min-height: 95vh;
        border-radius: 20px;
        background: transparent;
        margin-top: 10px;
        padding: 20px;

        @media (max-width: vars.$mobile-size) {
            padding: 5px;
            border-radius: 10px;
        }
    }
}

.error-message {
    color: var(--color-warning);
}

button.mdc-button {
    letter-spacing: 0;
    min-width: 78px;
    padding: 24px 14px 24px 14px;
}

button.mat-mdc-outlined-button {
    padding: 22px 12px 22px 12px;
    border-width: 2px;
}

button.mat-mdc-outlined-button:not(:disabled) {
    border-color: mat.get-color-from-palette($social-help-primary, 500);
    color: mat.get-color-from-palette($social-help-primary, 500);
}

button.mat-mdc-outlined-button.button-grey:not(:disabled) {
    background-color: var(--color-block-light);
    border-color: var(--color-container-blue-medium);
}

button.mat-mdc-outlined-button.button-light-blue:not(:disabled) {
    background-color: var(--color-container-blue-light);
    border-color: var(--color-container-blue-medium);
    font-weight: bold;
}

button.mat-mdc-unelevated-button.button-green:not(:disabled) {
    background-color: var(--color-label-green);
    border-color: var(--color-label-green);
    font-weight: 500;
}

button.mat-mdc-unelevated-button.button-small:not(:disabled) {
    height: 30px;
    padding: 0 10px;
}

button.mat-mdc-unelevated-button.button-high:not(:disabled) {
    height: 45px;
}

.select-filter .mat-mdc-form-field-flex {
    height: 50px;
    padding: 0 10px;
}

.text-highlight {
    color: mat.get-color-from-palette($social-help-primary, 500);
}

.text-grey {
    color: var(--color-block-grey);
}

.form-row {
    padding-bottom: 10px;
}

//tabs
div.mat-mdc-tab.mdc-tab--active span.mdc-tab__ripple:before {
    background-color: var(--color-block-light);
}

div.mat-mdc-tab,
div.mat-mdc-tab:not(.mdc-tab--stacked) {
    letter-spacing: 0;
    background-color: var(--color-block-grey-light);
    border-radius: var(--tab-border-radius) var(--tab-border-radius) 0 0;
    height: 90px;

    @media (max-width: vars.$mobile-size) {
        height: 50px;
    }
}

div.mat-mdc-tab.mdc-tab--active {
    letter-spacing: 0;
    background-color: var(--color-block-light);
}

div.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label,
div.mat-mdc-tab.mdc-tab--active:focus .mdc-tab__text-label {
    color: var(--color-label-black);
}

div.mat-mdc-tab .mdc-tab-indicator__content--underline,
div.mat-mdc-tab:hover .mdc-tab-indicator__content--underline,
div.mat-mdc-tab:focus .mdc-tab-indicator__content--underline,
div.mat-mdc-tab.mdc-tab--active .mdc-tab-indicator__content--underline,
div.mat-mdc-tab.mdc-tab--active:hover .mdc-tab-indicator__content--underline,
div.mat-mdc-tab.mdc-tab--active:focus .mdc-tab-indicator__content--underline {
    border-color: var(--color-block-light);
}

div.mat-mdc-tab-body-content {
    background-color: var(--color-block-light);
    border-radius: 0 0 var(--tab-border-radius) var(--tab-border-radius);
}

mat-tab-group.tabs-same-width div.mat-mdc-tab {
    width: 50%;
}

//Inputs

.mat-mdc-form-field .mdc-text-field {
    padding: 0;
}

label.mat-mdc-floating-label.mdc-floating-label mat-label {
    color: var(--color-label-black);
    font-weight: bold;
}

.mat-mdc-form-field .mat-icon {
    color: var(--color-label-grey);
}

div.mat-mdc-form-field-icon-prefix .mat-icon,
div.mat-mdc-form-field-icon-suffix .mat-icon {
    padding: 12px 0 5px 0;
    font-size: 18px;
}

.mat-mdc-form-field .mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field .mat-mdc-form-field-icon-suffix {
    align-self: self-end;
}

.mat-mdc-form-field-focus-overlay {
    background: var(--color-page);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input,
div.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: var(--color-page);

}

input.mat-mdc-input-element.mat-mdc-form-field-input-control {
    letter-spacing: 0;
}

//Text button
.text-button {
    color: var(--color-label-grey);
    font-size: 14px;
    text-decoration-style: dotted;
    display: inline-block;
    padding-top: 10px;
    border: none;
    cursor: pointer;
}

//Checkbox
.mat-mdc-checkbox {
    left: -12px;
}

//Error
.mat-mdc-form-field .mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field .mat-mdc-form-field-error-wrapper {
    padding: 0;
}

//Stepper
mat-stepper .mat-step {
    background: var(--color-block-light);
    margin-bottom: 10px;
    border-radius: 10px;
}

.mat-step .mat-vertical-content-container {
    margin-left: 0;
}

.mat-vertical-content-container::before {
    display: none;
}

.mat-step-text-label {
    font-weight: bold;
}

mat-stepper .mat-step-header:hover:not([aria-disabled]),
mat-stepper .mat-step-header:hover[aria-disabled='false'] {
    background: none;
    .mat-step-text-label {
        color: var(--color-label-blue);
    }
}

//Table
table .mdc-data-table__header-cell {
    color: var(--color-label-blue);
    font-weight: bold;
}

//Paginator
.paginator {
    margin-top: 5px;
    .mat-mdc-form-field-infix {
        padding: 7px 10px;
    }

    .mat-mdc-paginator-page-size-label {
        display: none;
    }
}

//Shepherd
.shepherd-main {
    margin: 10px;
    width: 500px;
    max-width: 500px;
    @media (max-width: vars.$mobile-size) {
        width: 90vw;
        max-width: 90vw;
        margin: 10px;
    }

    .shepherd-content {
        header.shepherd-header {
            background: var(--color-block-light);
        }
        .shepherd-cancel-icon {
            color: var(--color-label-black);
        }

        .shepherd-text {
            color: var(--color-label-grey);
            padding: 20px 30px 40px 30px;
        }

        .shepherd-button-primary {
            background: var(--color-label-blue);
            color: var(--color-label-white);
        }

        .shepherd-button-secondary {
            background: var(--color-label-white);
            color: var(--color-label-blue);
            border: solid 1px var(--color-label-blue);
        }
    }

    .shepherd-title {
        color: var(--color-label-black);
        font-weight: bold;
        display: block;
        text-align: center;
    }

    .shepherd-title-image-container {
        text-align: center;
    }

    .shepherd-title-image {
        padding-top: 10px;
        @media (max-width: vars.$mobile-size) {
            width: 50vw;
            max-width: 50vw;
        }
    }

    .shepherd-arrow {
        @media (max-width: vars.$mobile-size) {
            left: 20vw !important;
            // background-color: #fff;
        }
    }
}

div.mat-mdc-form-field-error-wrapper {
    position: static;
    margin-top: -20px;
}
